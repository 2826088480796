import { useRouter } from 'next/router'
import { useEffect } from 'react'
import BasicHead from '../components/common/BasicHead'
import LoadingSpinner from '../components/common/LoadingSpinner'
import urls from '../modules/urls'
import { locationMove } from '../modules/utils'

const Index = () => {
  const router = useRouter()

  useEffect(() => {
    locationMove(router, urls.student.landing.home)
  }, [])

  return (
    <>
      <BasicHead />
      <div className="fixed flex h-screen w-screen items-center justify-center">
        <LoadingSpinner show primary lg />
      </div>
    </>
  )
}

export default Index
